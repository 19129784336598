body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root, html, body {
    height: 100%;
    margin: 0;
    overflow: hidden;
    scrollbar-color: #ffffff21 #373754;
    scrollbar-width: thin;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #373754;
}

::-webkit-scrollbar {
    height: 4px;
    width: 6px;
    background-color: #08081c;
}

::-webkit-scrollbar-thumb {
    background-color: #ffffff21;
    border-radius: 0;
}

@font-face {
    font-family: 'Mediator Narrow Book';
    src: url('./assets/fonts/MediatorNarrow-Book.woff2') format('woff2'),
    url('./assets/fonts/MediatorNarrow-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mediator Narrow';
    src: url('./assets/fonts/MediatorNarrow-Bold.woff2') format('woff2'),
    url('./assets/fonts/MediatorNarrow-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mediator Narrow CAPS';
    src: url('./assets/fonts/MediatorNarrowCAPS-Regular.woff2') format('woff2'),
    url('./assets/fonts/MediatorNarrowCAPS-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mediator Narrow CAPS';
    src: url('./assets/fonts/MediatorNarrowCAPS-Medium.woff2') format('woff2'),
    url('./assets/fonts/MediatorNarrowCAPS-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mediator Narrow CAPS';
    src: url('./assets/fonts/MediatorNarrowCAPS-Bold.woff2') format('woff2'),
    url('./assets/fonts/MediatorNarrowCAPS-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mediator Narrow';
    src: url('./assets/fonts/MediatorNarrow-Bold.woff2') format('woff2'),
    url('./assets/fonts/MediatorNarrow-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mediator Narrow Book';
    src: url('./assets/fonts/MediatorNarrow-Book.woff2') format('woff2'),
    url('./assets/fonts/MediatorNarrow-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mediator Narrow CAPS';
    src: url('./assets/fonts/MediatorNarrowCAPS-Bold.woff2') format('woff2'),
    url('./assets/fonts/MediatorNarrowCAPS-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mediator Narrow CAPS';
    src: url('./assets/fonts/MediatorNarrowCAPS-Medium.woff2') format('woff2'),
    url('./assets/fonts/MediatorNarrowCAPS-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mediator Narrow CAPS';
    src: url('./assets/fonts/MediatorNarrowCAPS-Regular.woff2') format('woff2'),
    url('./assets/fonts/MediatorNarrowCAPS-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mediator Narrow';
    src: url('./assets/fonts/MediatorNarrow-Medium.woff2') format('woff2'),
    url('./assets/fonts/MediatorNarrow-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mediator Narrow';
    src: url('./assets/fonts/MediatorNarrow-Regular.woff2') format('woff2'),
    url('./assets/fonts/MediatorNarrow-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mediator Narrow';
    src: url('./assets/fonts/MediatorNarrow-Light.woff2') format('woff2'),
    url('./assets/fonts/MediatorNarrow-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mediator Narrow';
    src: url('./assets/fonts/MediatorNarrow-Medium.woff2') format('woff2'),
    url('./assets/fonts/MediatorNarrow-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mediator Narrow';
    src: url('./assets/fonts/MediatorNarrow-Light.woff2') format('woff2'),
    url('./assets/fonts/MediatorNarrow-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

